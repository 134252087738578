import { StorageHelper } from '@smg-real-estate/hg-search-libs';

const ACCOUNT_STORAGE_NAMESPACE = 'accounts';
const CALLBACK_URL_KEY = 'callbackUrl';
const storage = new StorageHelper(ACCOUNT_STORAGE_NAMESPACE);

export const persistCallbackUrl = (callbackUrl: any) => {
  const ONE_HOUR = 60 * 60;
  if (callbackUrl) {
    emptyPersistedCallbackUrl();
    storage.setLocalStorageExpirableItem(CALLBACK_URL_KEY, callbackUrl.toString(), ONE_HOUR);
  }
};

export const getCallbackUrl = (): string | undefined => {
  return storage.getLocalStorageExpirableItem(CALLBACK_URL_KEY)?.toString();
};

export const emptyPersistedCallbackUrl = () => {
  storage.removeLocalStorageItem(CALLBACK_URL_KEY);
};

export const validateCallbackUrl = (callbackUrl: string) => {
  if (!callbackUrl) {
    return;
  }
  const validCallbackHostNames: string[] = build.config.VALID_CALLBACK_HOST_NAMES;
  try {
    const decodedURL: URL = new URL(decodeURIComponent(callbackUrl));
    if (!validCallbackHostNames.some((validCallback) => decodedURL.hostname.toLowerCase().endsWith(validCallback))) {
      throw new Error(`Invalid callback url: ${callbackUrl}`);
    }
  } catch (error) {
    error.type = 'InvalidCallbackUrlError';
    throw error;
  }
};
