import { getHGOverviewListerUrlHref, getHGOverviewSeekerUrlHref } from '@/router/utils';
import { NavigationGuardNext, Route } from 'vue-router';
import { StorageHelper } from '@smg-real-estate/hg-search-libs';
import { Key, UserType } from '@/helpers/Storage';
import { isListerType } from '@/helpers/AccountTypeCheck';
import { authenticateTenantPlusUser, setTenantPlusCookie } from '@/helpers/TenantPlusService';
import { getAuth0Vue, VueAuth } from '@/auth/auth0Plugin';
import { GroupType, User } from '@/auth/types';

const redirectToLogin = async (targetRoute: Route, _sourceRoute: Route, next: NavigationGuardNext) => {
  const auth0 = await getAuth0Vue();
  auth0.loginWithRedirect({
    appState: { locale: targetRoute.params.locale },
    authorizationParams: {
      ui_locales: targetRoute.params.locale,
    },
  });
  next(false);
};

const redirectToUserLandingPage = (user: User, next: NavigationGuardNext, locale: string) => {
  if (user?.groups.includes(GroupType.AGENCY)) {
    const baseUrl = build.config.HOMEGATE_BASE_URL;
    window.location.assign(`${baseUrl}/${locale}/business/`);
    next(false);
  } else {
    next({
      path: `/${locale}/overview`,
      replace: true,
    });
  }
};

const handleLoginCallback = async (_targetRoute: Route, _sourceRoute: Route, next: NavigationGuardNext) => {
  const auth0 = await getAuth0Vue();
  const { targetPath, locale } = (await auth0.handleRedirectCallback()) ?? {};
  if (auth0.error) {
    // TODO we should probably show an error page with an error message
    console.log('error from callback', auth0.error);
    return next(false);
  }
  if (targetPath) {
    return next({
      path: targetPath,
      replace: true,
    });
  }
  redirectToUserLandingPage(auth0.user, next, locale);
};

const redirectUnAuthenticatedToLogin = (auth: VueAuth, to: Route, locale: string) =>
  new Promise<void>((resolve, reject) => {
    if (auth.isAuthenticated) {
      return resolve();
    }
    auth.loginWithRedirect({
      appState: { targetPath: to.fullPath },
      authorizationParams: {
        ui_locales: locale,
      },
    });
    reject();
  });

const getUnAuthorizedBaseUrlHref = (user: User, locale: string): string => {
  const baseUrl = new URL(`/${locale}`, build.config.HOMEGATE_BASE_URL);

  if (user.groups.includes(GroupType.PRIVATE)) {
    baseUrl.pathname = `/${locale}/overview/`;
  }

  return baseUrl.href;
};

const getOverviewRedirectionHref = async (
  storage: StorageHelper,
  targetLanguage: string,
  user: User,
): Promise<string> => {
  let redirectHref: string = '';
  let userType: UserType | null;

  const { isTenantPlusSubscriber } = await authenticateTenantPlusUser();
  if (isTenantPlusSubscriber) {
    setTenantPlusCookie(true);
    userType = UserType.SEEKER;
  } else {
    const isLister = await isListerType(user);
    userType = isLister ? UserType.LISTER : storage.getLocalStorageItem(Key.USER_TYPE);
  }

  if (userType != null && Object.values(UserType).includes(userType)) {
    switch (userType) {
      case UserType.LISTER:
        redirectHref = getHGOverviewListerUrlHref(targetLanguage);
        break;
      case UserType.SEEKER:
        redirectHref = getHGOverviewSeekerUrlHref(targetLanguage);
        break;
    }

    storage.setLocalStorageItem(Key.USER_TYPE, userType);
  }

  return redirectHref;
};

export {
  redirectToLogin,
  redirectToUserLandingPage,
  handleLoginCallback,
  redirectUnAuthenticatedToLogin,
  getUnAuthorizedBaseUrlHref,
  getOverviewRedirectionHref,
};
