import Cookies from 'js-cookie';
import store from '@/store';

export const MAX_IS24_AUTH0_RELEASE_VALUE = 100;
export const COOKIE_NAME = 'is24Auth0ReleaseCookie';

export type ApiResponse = {
  ratePercent: number;
};

// Check cookies for value. If it is there, return the value. If it is not, generate a random value, set the cookie to it and return that value
export const getCookieValue = (): number => {
  let cookieValue = Cookies.get(COOKIE_NAME);

  if (!cookieValue) {
    cookieValue = Math.ceil(Math.random() * MAX_IS24_AUTH0_RELEASE_VALUE).toString(); // Values will be 1-100
    Cookies.set(COOKIE_NAME, cookieValue);
  }

  return Number(cookieValue);
};

// Check if we should use Auth0. If the cookie value is less than or equal to the API Gateway value, return true. Otherwise, return false. If there is an error, return false
export const shouldWeUseAuth0 = async (): Promise<boolean> => {
  const is24Auth0ReleaseCookieValue = getCookieValue();

  const is24Auth0ReleaseAPIGatewayValue =
    store.getters['is24Auth0Release/is24Auth0ReleaseApiValue'] ??
    (await store.dispatch('is24Auth0Release/fetchIs24Auth0ReleaseApiValue'));

  return is24Auth0ReleaseCookieValue <= is24Auth0ReleaseAPIGatewayValue;
};
