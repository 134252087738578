import Vue from 'vue';
import Vuex from 'vuex';

import { ExperimentVuexModule } from '@smg-real-estate/vue-experiments';
import Is24Auth0ReleaseModule from '@/store/modules/Is24Auth0ReleaseModule';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    is24Auth0ReleaseApiValue: 0,
  },
  mutations: {},
  actions: {},
  modules: {
    experiments: ExperimentVuexModule,
    is24Auth0Release: Is24Auth0ReleaseModule,
  },
});
