<template>
  <div>
    <HgPageNotFound description=" " :displayBuyCta="false" :displayRentCta="false">
      <HgButton tag="a" :href="backToSignInPath">
        {{ $t('pageNotFound.cta') }}
      </HgButton>
    </HgPageNotFound>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { HgPageNotFound, HgButton } from '@smg-real-estate/domus';

export default Vue.extend({
  name: 'PageNotFound',
  components: {
    HgPageNotFound,
    HgButton,
  },
  computed: {
    backToSignInPath(): string {
      return `/${this.$i18n.locale}/sign-in`;
    },
  },
});
</script>
