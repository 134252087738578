import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { getIS24OverviewListerUrlHref, getIS24OverviewSeekerUrlHref } from '@/router/utils';
import { NavigationGuardNext, Route } from 'vue-router';
import { StorageHelper } from '@smg-real-estate/hg-search-libs';
import { Key, UserType } from '@/helpers/Storage';
import { GroupType, User } from '@/auth/types';
import { authenticateTenantPlusUser, setTenantPlusCookie } from '@/helpers/TenantPlusService';
import { getAuth0Vue } from '@/auth/auth0Plugin';
import { shouldWeUseAuth0 } from '@/helpers/Is24Auth0ReleaseHelper';
import { isPlatformIs24 } from '@/helpers/PlatformDetection';

type IS24RawProfile = {
  email: string;
  userId: number;
  username: string;
  personId: number;
  accountType: string;
  userType: string;
  propertiesCount: number;
  isSocialAccount?: boolean;
};

const redirectToLoginAuth0 = async (targetRoute: Route, _sourceRoute: Route, next: NavigationGuardNext) => {
  const auth0 = await getAuth0Vue();
  auth0.loginWithRedirect({
    appState: { locale: targetRoute.params.locale },
    authorizationParams: {
      ui_locales: targetRoute.params.locale,
    },
  });
  next(false);
};

const redirectToLogin = async (targetRoute: Route, _sourceRoute: Route, next: NavigationGuardNext, locale?: string) => {
  if (await shouldWeUseAuth0()) {
    await redirectToLoginAuth0(targetRoute, undefined, next);
  } else {
    const returnUrl = encodeURIComponent(`${build.config.IS24_BASE_URL}${targetRoute.fullPath}`);
    window.location.assign(`${build.config.IS24_BASE_URL}/private/login?lng=${locale}&returnUrl=${returnUrl}`);
    next(false);
  }
};

const redirectToLegacyAccountOverviewLandingPage = (next: NavigationGuardNext) => {
  window.location.assign(`${build.config.IS24_BASE_URL}/private/account/overview`);
  next(false);
};

const redirectToUserLandingPage = async (user: User, next: NavigationGuardNext, locale: string) => {
  if (user?.groups.includes(GroupType.AGENCY)) {
    const baseUrl = build.config.IS24_BASE_URL;
    if (isPlatformIs24() && (await shouldWeUseAuth0())) {
      window.location.assign(`${baseUrl}/${locale}/smg-business-manager`);
    } else {
      window.location.assign(`${baseUrl}/${locale}/business/`);
    }
    next(false);
  } else {
    next({
      path: `/${locale}/account-overview`,
      replace: true,
    });
  }
};

const handleLoginCallback = async (_targetRoute: Route, _sourceRoute: Route, next: NavigationGuardNext) => {
  const auth0 = await getAuth0Vue();
  const { targetPath, locale } = (await auth0.handleRedirectCallback()) ?? {};
  if (auth0.error) {
    // TODO we should probably show an error page with an error message
    console.log('error from callback', auth0.error);
    return next(false);
  }
  if (targetPath) {
    return next({
      path: targetPath,
      replace: true,
    });
  }
  redirectToUserLandingPage(auth0.user, next, locale);
};

const getOverviewRedirectionHref = async (
  storage: StorageHelper,
  targetLanguage: string,
  user: User,
): Promise<string> => {
  let redirectHref: string = '';
  let userType: UserType | null;

  const { isTenantPlusSubscriber } = await authenticateTenantPlusUser();
  if (isTenantPlusSubscriber) {
    setTenantPlusCookie(true);
    userType = UserType.SEEKER;
  } else {
    const isLister = user.propertiesCount > 0;
    userType = isLister ? UserType.LISTER : storage.getLocalStorageItem(Key.USER_TYPE);
  }

  if (userType != null && Object.values(UserType).includes(userType)) {
    switch (userType) {
      case UserType.LISTER:
        redirectHref = getIS24OverviewListerUrlHref(targetLanguage);
        break;
      case UserType.SEEKER:
        redirectHref = getIS24OverviewSeekerUrlHref(targetLanguage);
        break;
    }

    storage.setLocalStorageItem(Key.USER_TYPE, userType);
  }

  return redirectHref;
};

async function getAccessToken(): Promise<string | undefined> {
  const accessTokenPageUrl = `${process.env.VUE_APP_IS24_GET_TOKEN_PAGE_URL}`;
  try {
    const accessToken = await (
      await axios.get(accessTokenPageUrl, {
        withCredentials: true,
      })
    ).data.access_token;

    return accessToken;
  } catch (error) {
    Sentry.captureMessage(
      `${error.response?.status} ${error.response?.statusText} Failed to get user access token via ${accessTokenPageUrl} ${error}`,
    );
    return undefined;
  }
}

/**
  Calls IS24 API [GET] /users/userinfo endpoint, and returns an is24 user profile with relevant data

  Can return:
    - 200 Success
    - 401 Unauthorized
    - 400 Bad Request
  https://rest-api.immoscout24.ch/v4/swagger/index.html
**/
async function getUserInfo(accessToken: string): Promise<User | undefined> {
  try {
    const userProfileEndpoint = `${process.env.VUE_APP_IS24_USER_PROFILE_URL}`;
    const response = await axios.get(userProfileEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response?.data || !Object.keys(response?.data).length) {
      Sentry.captureMessage(`Unauthorized ${accessToken}`);
      return undefined;
    }

    const authorizedUser = convertToAuthorizedUser(response.data);
    return authorizedUser;
  } catch (error) {
    if (error.response?.status === 401) {
      Sentry.captureMessage(`Unauthorized ${accessToken} ${error}`);
      return undefined;
    }

    Sentry.captureException(`Failed to get user info ${error}`);
    return undefined;
  }
}

export function convertToAuthorizedUser(input: IS24RawProfile): User | undefined {
  const requiredFields = ['userId', 'personId', 'email', 'username'];
  const allFieldsAreValid = requiredFields.every((field) => input.hasOwnProperty(field));
  if (!allFieldsAreValid) {
    Sentry.captureMessage('Missing required fields from the response');
    return undefined;
  }

  const id = input.isSocialAccount ? String(input.userId) : `auth0|${input.userId}`;
  const authorizedUser: User = {
    id: id,
    personId: String(input.personId),
    email: input.email,
    emailVerified: true,
    username: input.username,
    groups: [GroupType.PRIVATE],
    propertiesCount: input.propertiesCount,
  };

  switch (input.accountType) {
    case 'G':
      authorizedUser.groups = [GroupType.PRIVATE];
      break;
    case 'M':
      authorizedUser.groups = [GroupType.AGENCY];
      break;
    default:
      break;
  }

  return authorizedUser;
}

export {
  redirectToLogin,
  redirectToLegacyAccountOverviewLandingPage,
  getOverviewRedirectionHref,
  getAccessToken,
  getUserInfo,
  redirectToUserLandingPage,
  handleLoginCallback,
};
