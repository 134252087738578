import * as Sentry from '@sentry/vue';

export enum AUTH_MESSAGES {
  SIGN_IN = 'signIn',
  SIGN_IN_FAILURE = 'signIn_failure',
  CHANGE_PASSWORD_START = 'changePassword_start',
  CHANGE_PASSWORD_SUCCESS = 'changePassword_success',
  CHANGE_PASSWORD_FAILURE = 'changePassword_failure',
  CHANGE_EMAIL_SUCCESS = 'changeEmail_success',
  FORGOT_PASSWORD_START = 'forgotPassword_start',
  FORGOT_PASSWORD_FAILURE = 'forgotPassword_failure',
  FORGOT_PASSWORD_SUCCESS = 'forgotPassword_success',
  USERNAME_WITH_WHITESPACE_FAILURE = 'usernameWithWhitespace_failure',
}

// TODO should we keep? As all those events are handled by Auth0 hosted ui there should be no need for this anymore
const listener = async (data) => {
  const forwardMessageToSentry = function (payload) {
    Sentry.captureMessage(payload.event, {
      tags: {
        message: payload.event,
        data: payload.message ? payload.message : '',
      },
    });
  };
  switch (data.payload.event) {
    case AUTH_MESSAGES.SIGN_IN:
    case AUTH_MESSAGES.SIGN_IN_FAILURE:
    case AUTH_MESSAGES.CHANGE_PASSWORD_START:
    case AUTH_MESSAGES.CHANGE_PASSWORD_SUCCESS:
    case AUTH_MESSAGES.CHANGE_PASSWORD_FAILURE:
    case AUTH_MESSAGES.CHANGE_EMAIL_SUCCESS:
    case AUTH_MESSAGES.FORGOT_PASSWORD_START:
    case AUTH_MESSAGES.FORGOT_PASSWORD_FAILURE:
    case AUTH_MESSAGES.FORGOT_PASSWORD_SUCCESS:
    case AUTH_MESSAGES.USERNAME_WITH_WHITESPACE_FAILURE:
      forwardMessageToSentry(data.payload);
      break;
  }
};

export default listener;
