<template>
  <router-view></router-view>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'LayoutBlank',
});
</script>
