import { defineComponent } from 'vue';
import { Route } from 'vue-router';
import { MetaInfo } from 'vue-meta';
import { HgSupportedLanguage } from '@smg-real-estate/domus';
import { Hub } from '@aws-amplify/core';
import listener from '@/helpers/AuthListener';
import { getLanguageSwitchLink } from '@/router/utils';
import { GroupType, User } from '@/auth/types';
import { HeaderTrackingData, TrackingData } from '@/helpers/types';
import { EventCategory, GTMEvent, trackEvent, InteractionGoal, EventLabel } from '@/helpers/GTM';

const layoutMixin = defineComponent({
  name: 'layoutMixin',
  data() {
    return {
      InteractionGoal,
      EventLabel,
    };
  },
  computed: {
    currentLocale(): string {
      return this.$i18n.locale;
    },
    currentRoute(): Route {
      return this.$route;
    },
    isMainNavigationHidden(): boolean {
      if (this.$route.meta.isMenuNavigationShow) {
        return false;
      }

      return this.$route.meta.isPrivate;
    },
    currentUser(): User | null {
      return this.$route.meta?.user || null;
    },
    isPrivateView(): boolean {
      return !!this.$route.meta?.isPrivate;
    },
    isPrivateUser(): boolean {
      return !!this.currentUser?.groups?.includes(GroupType.PRIVATE);
    },
  },
  beforeMount() {
    Hub.listen('auth', listener);
  },
  methods: {
    getLanguageSwitchLink(language: HgSupportedLanguage): Route {
      return getLanguageSwitchLink(this.$route, language);
    },
    trackClickHandler(data: TrackingData) {
      const dataObj = {
        event: GTMEvent.FOOTER_CLICK,
        h_event_category_006: EventCategory.FOOTER_CLICK,
        h_event_action_007: data.eventAction,
        h_event_label_008: data.eventLabel,
        non_interaction: false,
      };
      trackEvent(dataObj);
    },
    trackHeaderClickHandler(data: HeaderTrackingData) {
      const dataObj: any = {
        event: GTMEvent.HEADER_CLICK,
        h_event_category_006: EventCategory.HEADER_CLICK,
        h_event_action_007: data.eventAction,
        h_event_label_008: data.eventLabel,
      };
      if (data.contentLanguage) {
        dataObj['h_content_language_116'] = data.contentLanguage;
      }
      trackEvent(dataObj);
    },
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('layoutDefault.pageTitle').toString(),
    };
  },
});

export default layoutMixin;
