import Vue, { VueConstructor } from 'vue';
import { Auth0Client, GetTokenSilentlyOptions, LogoutOptions, RedirectLoginOptions, User } from '@auth0/auth0-spa-js';
import { initAuth0Client } from '@/auth/auth0Client';
import * as authTypes from '@/auth/types';

let instance;

export interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience?: string;
  cookieDomain?: string;
}

export interface AppState {
  targetPath?: string;
  locale?: string;
}

export interface VueAuth extends Vue {
  auth0Client: Auth0Client;
  isLoading: boolean;
  isAuthenticated: boolean;
  user?: authTypes.User;
  error?: Error;
  loginWithRedirect: (options?: RedirectLoginOptions) => Auth0Client['loginWithRedirect'];
  handleRedirectCallback: () => Promise<AppState | undefined>;
  logout: () => Auth0Client['logout'];
  getAccessTokenSilently: () => Promise<string>;
}

export const getAuth0Vue = (): Promise<VueAuth> => {
  return new Promise<VueAuth>((resolve, reject) => {
    if (!instance) {
      reject('auth0 plugin not installed');
    }
    if (!instance.isLoading) {
      resolve(instance);
    }
    instance.$watch('isLoading', (isLoading) => {
      if (!isLoading) {
        resolve(instance);
      }
    });
  });
};

export const mapAuth0User = (auth0User?: User): authTypes.User | undefined => {
  if (!auth0User) {
    return undefined;
  }
  return {
    id: auth0User.sub as string,
    personId: auth0User['cognito:username'],
    username: auth0User.preferred_username ?? (auth0User.email as string),
    name: auth0User.name,
    email: auth0User.email,
    emailVerified: auth0User.email_verified,
    picture: auth0User.picture,
    groups: auth0User['cognito:groups'] ?? [],
  };
};

export const useAuth0 = (auth0PluginOptions: Auth0PluginOptions) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        auth0Client: undefined as unknown as Auth0Client,
        isLoading: true,
        isAuthenticated: false,
        user: undefined as unknown as authTypes.User | undefined,
        error: null,
      };
    },
    methods: {
      async handleRedirectCallback(): Promise<AppState | undefined> {
        this.isLoading = true;
        try {
          const { appState } = await this.auth0Client.handleRedirectCallback();
          this.user = mapAuth0User(await this.auth0Client.getUser());
          this.isAuthenticated = true;
          this.error = null;
          return appState;
        } catch (e) {
          this.error = e;
        } finally {
          this.isLoading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      async loginWithRedirect(options: RedirectLoginOptions = {}) {
        return this.auth0Client.loginWithRedirect(options);
      },
      /** Returns all the claims present in the ID token */
      async getIdTokenClaims() {
        return this.auth0Client.getIdTokenClaims();
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently(options?: GetTokenSilentlyOptions) {
        return this.auth0Client.getTokenSilently(options);
      },
      /** Logs the user out and removes their session on the authorization server */
      async logout(options: LogoutOptions = {}) {
        return this.auth0Client.logout(options);
      },
    },
    async created() {
      this.auth0Client = await initAuth0Client({
        domain: auth0PluginOptions.domain,
        clientId: auth0PluginOptions.clientId,
        authorizationParams: {
          redirect_uri: auth0PluginOptions.redirectUri,
          audience: auth0PluginOptions.audience,
        },
        cookieDomain: auth0PluginOptions.cookieDomain,
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
      });
      this.isAuthenticated = await this.auth0Client.isAuthenticated();
      this.user = mapAuth0User(await this.auth0Client.getUser());
      this.isLoading = false;
    },
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue: VueConstructor, options: Auth0PluginOptions) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
