import axios from 'axios';

export type Config = {
  domainPrefix: string;
  userPoolId: string;
  accountsAppClientId: string;
  homegatePlusApiUrl: string;
  siteUrl: string;
  dataWarehouseApiUrl?: string;
  customerServiceTopic: string;
  insertionFunnelApiUrl: string;
};

class Configuration {
  private config?: Config;

  public async getConfig(): Promise<Config> {
    if (this.config) {
      return this.config;
    }
    if (process.env.NODE_ENV !== 'development') {
      try {
        this.config = (await axios.get('/api/config')).data as Config;
      } catch (error) {
        error.type = 'GeneralError';
        throw error;
      }
    } else {
      this.config = await this.getLocalConfig();
    }
    this.assertRequiredConfigIsSet(this.config);
    return this.config;
  }

  /**
   * This solution prevents us from using multiple 'personal' stacks because
   * we can not guarantee to which AWS stack we actually connect to.
   * We are assuming that every developer has only one personal stack running at any given time
   * before running the application locally
   */
  private async getLocalConfig(): Promise<Config> {
    const config = {};
    const output = await import('../../aws-config.json');
    const personalStackName = Object.keys(output.default).toString();
    const localConfig = output[personalStackName];
    const entries = Object.keys(localConfig);
    for (const entry of entries) {
      config[entry.charAt(0).toLowerCase() + entry.slice(1)] = localConfig[entry];
    }
    return config as Config;
  }

  private assertRequiredConfigIsSet(config: Config) {
    if (!config.domainPrefix) {
      throw new Error('Required config value domainPrefix is missing');
    }
    if (!config.userPoolId) {
      throw new Error('Required config value userPoolId is missing');
    }
    if (!config.accountsAppClientId) {
      throw new Error('Required config value accountsAppClientId is missing');
    }
  }
}

export default new Configuration();
