import configuration from '@/helpers/Configuration';
import axios from 'axios';
import { UserType } from '@/helpers/Storage';
import { User } from '@/auth/types';
import { getAuth0Client } from '@/auth/auth0Client';

export async function isListerType(user: User | null): Promise<boolean> {
  if (!user) {
    return false;
  }

  const config = await configuration.getConfig();
  if (!config.insertionFunnelApiUrl) {
    return false;
  }

  try {
    const response = await axios.get(`${config.insertionFunnelApiUrl}/users/${user.personId}/userType`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAuth0Client().getTokenSilently()}`,
      },
      withCredentials: true,
    });

    return response.data.userType === UserType.LISTER ? true : false;
  } catch (error) {
    return false;
  }
}
