import { Route } from 'vue-router';
import { DEFAULT_LANGUAGE } from '@/i18n';

import {
  getOverviewRedirectionHref,
  redirectUnAuthenticatedToLogin,
  redirectToUserLandingPage,
} from '@/router/hg/guardHelper';
import { userTypeStorage } from '@/helpers/Storage';
import { StorageHelper } from '@smg-real-estate/hg-search-libs';
import { getAuth0Vue } from '@/auth/auth0Plugin';
import { setLocaleBeforeRouteChange } from '@/i18n-setup';
import { HgSupportedLanguage } from '@smg-real-estate/domus';
import {
  getLocale,
  isProtectedRoute,
  isUserAuthorizedForRoute,
  nextValidation,
  setupRouteMetaValues,
  updateRouteMetaValues,
} from '../utils';

const storage: StorageHelper = userTypeStorage;

const guards = (localeWatcher?: (locale: string) => void) => {
  const beforeEach = async (targetRoute: Route, sourceRoute: Route, next: (route?) => void) => {
    // get the current language
    const targetLanguage = targetRoute.params.locale || DEFAULT_LANGUAGE;

    const locale = getLocale(targetRoute, targetLanguage as HgSupportedLanguage);
    await setLocaleBeforeRouteChange(locale);

    // Important: Resetting the additional values in the meta key
    if (targetRoute.meta) {
      Object.assign(targetRoute.meta, setupRouteMetaValues(targetLanguage, targetRoute.meta));
    }

    // Validation of routes
    const { nextRoute, nextLocale } = nextValidation({ targetRoute, sourceRoute, targetLanguage });
    if (!!localeWatcher && nextLocale) {
      localeWatcher(nextLocale);
    }

    if (nextRoute) {
      return next(nextRoute);
    }

    const auth = await getAuth0Vue();

    if (isProtectedRoute(targetRoute)) {
      try {
        await redirectUnAuthenticatedToLogin(auth, targetRoute, targetLanguage);
      } catch (err) {
        return next(false);
      }
    }

    if (auth.user) {
      if (!isUserAuthorizedForRoute(auth.user, targetRoute)) {
        return redirectToUserLandingPage(auth.user, next, targetLanguage);
      }

      // Re-assign targetRoute with additional values to the meta key.
      // These values are available in each component (e.g. `$route.meta?.user`)
      Object.assign(targetRoute.meta, updateRouteMetaValues(auth.user, targetRoute.meta));
      // Special navigation check for the overview page
      // The usertype from local storage would be checked for navigating to responding page.
      if (targetRoute.name === 'overview') {
        const redirectHref: string = await getOverviewRedirectionHref(storage, targetLanguage, auth.user);
        if (redirectHref !== '') {
          return next(redirectHref);
        }
      }
    }

    next();
  };

  return {
    beforeEach,
  };
};

export default guards;
