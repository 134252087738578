import { StorageHelper } from '@smg-real-estate/hg-search-libs';

export enum Key {
  CLIENT_LOGIN_PROVIDER = 'clientLoginProvider',
  USER_TYPE = 'userType',
}

export enum StorageNamespace {
  ACCOUNT_PAGE = 'hg-account-page',
}
export enum UserType {
  SEEKER = 'seeker',
  LISTER = 'lister',
}

export const trackingStorage: StorageHelper = new StorageHelper('tracking');
export const userTypeStorage: StorageHelper = new StorageHelper(StorageNamespace.ACCOUNT_PAGE);
